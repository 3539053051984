<template>
            
	<svg version="1.1" class="product-logo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 881.1 319" style="enable-background:new 0 0 881.1 319;" xml:space="preserve" role="img" aria-labelledby="logo_title" aria-description="logo_desc">
	 <title id="logo_title">Advisgo</title>
        <desc id="logo_desc">Logo for advisgo</desc>
		<g>
			<g>
				<path class="product-logo__text" d="M105.3,78.7c-3.5-6.7-8.9-12.1-16.1-16.2c-7.2-4-16.3-6-27.4-6c-7.4,0-14.9,0.9-22.7,2.6
					c-7.7,1.8-15.2,4.4-22.4,7.9l7.9,20.2c6.3-2.8,12.4-4.8,18.3-5.9c5.9-1.1,11.3-1.7,16.2-1.7c9.3,0,15.8,2.3,19.5,6.8
					c3.7,4.6,5.5,9.5,5.5,15v10.9c-0.3-0.3-0.6-0.6-0.9-0.8c-7.5-5.5-17.4-8.3-29.7-8.3c-9.8,0-18.4,1.9-25.6,5.8
					c-7.2,3.9-12.7,9.1-16.6,15.6c-3.9,6.6-5.8,14.1-5.8,22.5c0,9.1,1.9,16.9,5.7,23.5c3.8,6.6,9.3,11.6,16.5,15.1
					c7.2,3.5,15.8,5.3,25.8,5.3c10.9,0,20.4-2.6,28.5-7.8c2.5-1.6,4.7-3.6,6.7-5.7l2.7,13.7h19v-89.8C110.6,93,108.8,85.5,105.3,78.7z
					M77.1,162.3c-4.7,3.6-11,5.4-18.7,5.4c-7.7,0-14-1.8-18.7-5.4c-4.7-3.6-7.1-8.6-7.1-15.1c0-6.7,2.4-11.7,7.1-15.2
					c4.7-3.5,11-5.3,18.7-5.3c7.7,0,14,1.8,18.7,5.3c4.7,3.5,7.1,8.6,7.1,15.2C84.2,153.6,81.8,158.7,77.1,162.3z"></path>
				<path class="product-logo__text" d="M230.7,1.9v69.8c-2.6-2.6-5.4-5-8.4-6.9c-8.6-5.6-18.7-8.4-30.3-8.4c-11.8,0-22.1,2.8-31.1,8.4
					c-9,5.6-15.9,13.4-20.8,23.5c-4.9,10.1-7.4,21.8-7.4,35.3c0,13.4,2.5,25.2,7.4,35.4c4.9,10.2,11.9,18.1,20.8,23.7
					c9,5.7,19.3,8.5,31.1,8.5c11.6,0,21.7-2.8,30.3-8.5c4.1-2.7,7.7-5.9,11-9.7l3.8,18.4h20V1.9H230.7z M222.1,156
					c-6.6,8.1-15.3,12.2-26.2,12.2c-10.9,0-19.7-4.1-26.4-12.3c-6.7-8.2-10-19-10-32.5c0-13.3,3.3-23.9,10-32.1
					c6.7-8.1,15.5-12.2,26.4-12.2c10.9,0,19.6,4.1,26.2,12.2c6.6,8.1,9.9,18.9,9.9,32.3C232,137.1,228.7,147.9,222.1,156z"></path>
				<polygon class="product-logo__text" points="374.3,56.7 341.7,155.9 307.6,56.7 278.4,56.7 329.5,191 354.5,191 403,56.7 		"></polygon>
				<g>
					<rect x="428.8" y="56.7" class="product-logo__text" width="26.4" height="134.4"></rect>
					<path class="product-logo__text" d="M442,36.7c5.1,0,9.2-1.6,12.4-4.9c3.2-3.2,4.7-7.3,4.7-12.3c0-5.1-1.6-9.3-4.7-12.5
						c-3.2-3.2-7.3-4.9-12.4-4.9c-5.3,0-9.4,1.6-12.5,4.9c-3.1,3.3-4.6,7.4-4.6,12.5c0,4.9,1.5,9,4.6,12.3
						C432.5,35.1,436.7,36.7,442,36.7z"></path>
				</g>
				<path class="product-logo__text" d="M531.9,112.5c-9.1-2.1-15.4-4.5-18.8-7.3c-3.4-2.7-5.1-6.3-5.1-10.7c0-4.4,1.8-7.8,5.5-10.3
					c3.7-2.5,7.7-3.7,12.1-3.7c7.2,0,13.2,1.5,17.9,4.6c4.7,3.1,8.3,6.2,10.8,9.4l20.3-15.1c-4.4-6.2-10.5-11.5-18.2-16
					c-7.7-4.5-17-6.7-27.9-6.7c-9.1,0-17,1.8-23.7,5.3c-6.7,3.5-11.8,8.2-15.3,14c-3.5,5.8-5.3,12.3-5.3,19.3
					c0,11.8,3.4,20.5,10.3,25.9c6.9,5.5,16.6,9.9,29.3,13.2c6.9,1.8,12.1,3.6,15.8,5.4s6.3,3.8,7.8,5.9c1.5,2.1,2.2,4.6,2.2,7.4
					c0,4.2-1.5,7.8-4.6,10.7c-3.1,2.9-7.4,4.4-13,4.4c-7,0-12.7-1.5-17.1-4.4c-4.4-2.9-9.1-7.6-14-14.2l-20,13.2
					c5.6,10.2,12.8,17.5,21.5,21.7c8.7,4.2,17.5,6.3,26.5,6.3c8.1,0,15.6-1.6,22.5-4.9c6.9-3.3,12.6-7.9,16.9-14
					c4.3-6.1,6.5-13.4,6.5-21.8c0-9.7-3.3-17.5-9.8-23.4C558.3,121,547.3,116.2,531.9,112.5z"></path>
			</g>
			<g>
				<path class="product-logo__text" d="M810.8,191c-13,0-24.5-2.8-34.4-8.3c-9.9-5.5-17.6-13.3-23.1-23.3c-5.5-10-8.2-21.7-8.2-35.1
					c0-13.5,2.7-25.3,8.2-35.3c5.4-10,13.1-17.8,23.1-23.5c9.9-5.6,21.4-8.4,34.4-8.4c13.2,0,24.7,2.8,34.5,8.4
					c9.8,5.6,17.5,13.4,23.1,23.5c5.5,10,8.3,21.8,8.3,35.3c0,13.4-2.8,25-8.3,35.1c-5.5,10-13.2,17.8-23.1,23.3
					C835.4,188.2,823.9,191,810.8,191z M810.8,165.7c11.8,0,21.2-3.8,28.3-11.5c7.1-7.6,10.7-17.6,10.7-29.9
					c0-12.5-3.6-22.6-10.7-30.3c-7.1-7.7-16.6-11.6-28.3-11.6c-11.6,0-21,3.8-28.1,11.5S772,111.6,772,124.1
					c0,12.5,3.6,22.5,10.7,30.2C789.8,161.9,799.2,165.7,810.8,165.7z"></path>
			</g>
			<g>
				<path class="product-logo__icon" d="M659.5,191c-12.8,0-24-2.8-33.8-8.3c-9.8-5.5-17.3-13.3-22.6-23.3c-5.3-10-8-21.7-8-35.1
					c0-13.5,2.7-25.3,8-35.3c5.3-10,12.9-17.8,22.6-23.5c9.7-5.6,21-8.4,33.8-8.4c12.9,0,24.2,2.8,33.9,8.4
					c9.7,5.6,17.2,13.4,22.6,23.5c5.4,10,8.2,21.8,8.2,35.3c0,13.4-2.7,25-8.2,35.1c-5.4,10-13,17.8-22.6,23.3
					C683.8,188.2,672.5,191,659.5,191z M659.5,165.7c11.6,0,20.8-3.8,27.8-11.5c7-7.6,10.5-17.6,10.5-29.9c0-12.5-3.5-22.6-10.5-30.3
					c-7-7.7-16.3-11.6-27.8-11.6c-11.4,0-20.6,3.8-27.6,11.5c-7,7.6-10.5,17.7-10.5,30.2c0,12.5,3.5,22.5,10.5,30.2
					C639,161.9,648.2,165.7,659.5,165.7z"></path>
			</g>
			<path class="product-logo__icon" d="M716.1,215.7c-0.9-1.6-1.8-3.1-2.8-4.5h-54.4v22.9h36.3c1.7,4.9,2.6,10.3,2.6,16.3c0,12.1-3.5,21.9-10.5,29.4
				c-7,7.5-16.3,11.3-27.8,11.3c-11.4,0-20.6-3.8-27.6-11.3c-7-7.5-10.5-17.4-10.5-29.6c0-5.7,0.8-10.9,2.3-15.6l-20-20
				c-0.2,0.4-0.5,0.7-0.7,1.1c-5.3,9.8-8,21.4-8,34.7c0,13.1,2.7,24.6,8,34.4c5.3,9.8,12.9,17.5,22.6,22.9c9.7,5.4,21,8.2,33.8,8.2
				c12.9,0,24.2-2.7,33.9-8.2c9.7-5.4,17.2-13.1,22.6-22.9c5.4-9.8,8.2-21.3,8.2-34.4C724.2,237.1,721.5,225.6,716.1,215.7z"></path>
			<path class="product-logo__icon" d="M706.9,36.1c5,0,9.1-1.6,12.2-4.8c3.1-3.2,4.7-7.2,4.7-12c0-5-1.6-9.1-4.7-12.3c-3.1-3.2-7.2-4.8-12.2-4.8
				c-5.2,0-9.3,1.6-12.3,4.8c-3,3.2-4.5,7.3-4.5,12.3c0,4.8,1.5,8.8,4.5,12C697.6,34.5,701.7,36.1,706.9,36.1z"></path>
		</g>

</svg>

</template>

<script lang="ts">
    export default {
        name: "product-logo"
    };
</script>

<style lang="scss" scoped>
	.product-logo {
		&__text {    
			fill: rgb(var(--v-theme-logo-text));
		}
		&__icon {
			fill: rgb(var(--v-theme-logo-icon));
		}
	}
</style>
