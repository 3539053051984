export default [
  // {
  //   path: "",
  //   name: 'runtime-landing',
  //   component: () => import('@/views/Runtime/Landing.vue'),
  //   meta: {
  //     displayName: "navigation.home",
  //     icon: "$homeVariant",
  //   },
  // },
  {
    path: "Conversations",
    name: "Conversations",
    redirect: {name: "ViewConversations" },
    meta: {
      displayName: 'navigation.guidedConversations',
      icon: "$forum",
    },
    children: [
      {
        path: "",
        name: 'ViewConversations',
        component: () => import('@/views/Runtime/Conversations/GuidedConversations.vue'),
        props: {
          id: '1111-222-3333-1111'
        },
      },
      {
        path: 'ConversationsList/:id',
        name: 'ConversationsList',
        component: () => import('@/views/Runtime/Conversations/ConversationsList.vue'),
        props: true
      },
      {
        path: 'conversation/:id',
        name: 'RunConversation',
        component: () => import('@/views/Runtime/Conversations/GuidedConversation.vue'),
        props: true,
      }
    ]
  },
]