<template>
            
    <svg version="1.1" class="flow-pattern-bb-top-right"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 209.4 293.7" style="enable-background:new 0 0 209.4 293.7;" xml:space="preserve" role="img" aria-labelledby="flow-pattern-bb-top-right-title" aria-describedby="flow-pattern-bb-top-right-desc">
     <title id="flow-pattern-bb-top-right-title">Flow diagram</title>
        <desc id="flow-pattern-bb-top-right-desc">Flow diagram</desc>
		<g>
			<rect x="174.2" y="-0.1" transform="matrix(3.937242e-10 -1 1 3.937242e-10 141.5217 209.4399)" class="st0" width="2.6" height="68.2"/>
	
		<rect x="139.6" y="29.9" transform="matrix(6.794228e-10 -1 1 6.794228e-10 42.3763 239.461)" class="st0" width="2.6" height="137.3"/>
	
		<rect x="157.1" y="111.1" transform="matrix(7.739445e-10 -1 1 7.739445e-10 -4.7193 321.5032)" class="st0" width="2.6" height="103.9"/>
	
		<rect x="175" y="0" transform="matrix(-1 -6.346489e-10 6.346489e-10 -1 352.5782 195.6774)" class="st0" width="2.6" height="195.7"/>
	
		<rect x="141.4" y="0" transform="matrix(-1 -5.721078e-10 5.721078e-10 -1 285.3549 259.5878)" class="st0" width="2.6" height="259.5"/>
	
		<rect x="141.4" y="159.2" transform="matrix(1.063907e-09 -1 1 1.063907e-09 -83.442 368.7787)" class="st0" width="2.6" height="133.8"/>
	<circle class="st0" cx="142.7" cy="98.5" r="3.9"/>
	<path class="st3" d="M162.8-4"/>
	
		<rect x="69.9" y="96.1" transform="matrix(7.924222e-10 -1 1 7.924222e-10 -60.2289 202.4993)" class="st0" width="2.6" height="70.6"/>
	
		<rect x="103.9" y="33.4" transform="matrix(-1 -6.666300e-10 6.666300e-10 -1 210.2938 197.8098)" class="st0" width="2.6" height="131"/>
	<circle class="st1" cx="105.3" cy="98.5" r="3.9"/>
	
		<rect x="72.3" y="0" transform="matrix(-1 -5.038815e-10 5.038815e-10 -1 147.1062 99.6177)" class="st0" width="2.6" height="99.6"/>
	
		<rect x="54" y="143.8" transform="matrix(4.832713e-10 -1 1 4.832713e-10 -107.9946 218.5635)" class="st0" width="2.6" height="38.9"/>
	<circle class="st2" cx="105.1" cy="34" r="3.9"/>
	
		<rect x="103.6" y="194.2" transform="matrix(-1 -3.162582e-10 3.162582e-10 -1 209.6602 454.2783)" class="st0" width="2.6" height="65.8"/>
	
		<rect x="158.2" y="177.6" transform="matrix(3.709815e-10 -1 1 3.709815e-10 -34.9336 353.8617)" class="st1" width="2.6" height="33.6"/>
	<circle class="st1" cx="142.7" cy="194.4" r="3.9"/>
	<circle class="st1" cx="176.3" cy="163.1" r="3.9"/>
	<circle class="st0" cx="74" cy="226.1" r="3.9"/>
	
		<rect x="190.9" y="48.5" transform="matrix(7.185106e-10 -1 1 7.185106e-10 126.4131 257.9882)" class="st2" width="2.6" height="34.7"/>
	
		<rect x="139.9" y="222.7" transform="matrix(3.318937e-10 -1 1 3.318937e-10 -117.5424 399.9954)" class="st0" width="2.6" height="72.2"/>
	<circle class="st3" cx="142.7" cy="258.8" r="3.9"/>
	
		<rect x="175" y="225.5" transform="matrix(-1 -4.790072e-10 4.790072e-10 -1 352.5782 513.624)" class="st0" width="2.6" height="62.5"/>
	<circle class="st3" cx="176.3" cy="286.8" r="3.9"/>
	
		<rect x="69.7" y="160.1" transform="matrix(3.333148e-10 -1 1 3.333148e-10 -124.2173 266.1708)" class="st0" width="2.6" height="70.3"/>
	
		<rect x="72.7" y="132.1" transform="matrix(-1 -2.579817e-10 2.579817e-10 -1 147.9636 420.5075)" class="st0" width="2.6" height="156.2"/>
	
		<rect x="106.4" y="-36" transform="matrix(1.007052e-09 -1 1 1.007052e-09 41.9255 173.5006)" class="st0" width="2.6" height="203.5"/>
	<rect x="38.6" y="-1" transform="matrix(3.318937e-10 -1 1 3.318937e-10 5.9064 73.8245)" class="st0" width="2.6" height="69.9"/>
	<circle class="st2" cx="5.9" cy="65.8" r="3.9"/>
	<circle class="st0" cx="74" cy="286.8" r="3.9"/>
	
		<rect x="35.8" y="98.5" transform="matrix(-1 -4.662147e-10 4.662147e-10 -1 74.2469 294.091)" class="st4" width="2.6" height="97"/>
	<circle class="st4" cx="37.1" cy="98.6" r="3.9"/>
	<circle class="st2" cx="5.9" cy="34" r="3.9"/>
	<polyline class="st2" points="106.4,64.5 106.4,67.1 5.6,67.1 5.6,64.5 	"/>
	<polyline class="st2" points="74.8,32.7 74.8,35.2 5.1,35.2 5.1,32.7 	"/>
	
		<rect x="72.3" y="0" transform="matrix(-1 -7.092714e-10 7.092714e-10 -1 147.1062 34.2776)" class="st2" width="2.6" height="34.3"/>
	
		<rect x="103.9" y="32.7" transform="matrix(-1 -7.092714e-10 7.092714e-10 -1 210.2778 99.6177)" class="st2" width="2.6" height="34.3"/>
	
		<rect x="4.7" y="32.7" transform="matrix(-1 -7.092714e-10 7.092714e-10 -1 11.882 99.6177)" class="st2" width="2.6" height="34.3"/>
	
		<rect x="54.3" y="175.5" transform="matrix(-8.158755e-10 1 -1 -8.158755e-10 250.7467 139.6414)" class="st4" width="2.6" height="39.4"/>
	
		<rect x="72.7" y="193.9" transform="matrix(-1 -6.765798e-10 6.765798e-10 -1 147.9636 420.5262)" class="st4" width="2.6" height="32.7"/>
	<circle class="st4" cx="74" cy="226.1" r="3.9"/>
	<circle class="st4" cx="74" cy="195.2" r="3.9"/>
	<rect x="103.9" y="98.6" class="st1" width="2.6" height="65.8"/>
	
		<rect x="139.8" y="128.2" transform="matrix(7.718129e-10 -1 1 7.718129e-10 -22.0713 304.1512)" class="st1" width="2.6" height="69.9"/>
	<rect x="175" y="162.1" class="st1" width="2.6" height="33.6"/>
	
		<rect x="175" y="257.5" transform="matrix(-1 -6.765798e-10 6.765798e-10 -1 352.5782 547.676)" class="st3" width="2.6" height="32.7"/>
	
		<rect x="157.7" y="242.4" transform="matrix(6.765797e-10 -1 1 6.765797e-10 -99.7426 417.7952)" class="st3" width="2.6" height="32.7"/>






		</g>
    </svg>
    
</template>


<script lang="ts">
    export default {
        name: "flow-pattern-BB-top-right"
    };
</script>