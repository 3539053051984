<template>
    <div
        :class="'page-container screen--' + screen"
        >
        <v-container
            fluid 
            >
            <section 
                v-if="title != null && title != undefined"
                class="toolbar toolbar--heading"
                >
                <h1 class="toolbar__section-title">{{title}}</h1>
            
                <v-switch 
                    label="Toggle theme"
                    inset
                    :value="theme.global.name" 
                    @change="toggleTheme"
                    class="theme-toggler"
                    size="large" 
                ></v-switch>

            </section>

            <slot></slot>
        </v-container>
    </div>
</template>


<script lang="ts" setup>
    import { useTheme } from 'vuetify'
    const theme = useTheme()
    function toggleTheme () { 
        theme.global.name.value = theme.global.current.value.dark ? 'defaultTheme' : 'defaultThemeDark'
        console.log(theme);
    }
</script>


<script lang="ts">

export default {
    props: {'screen': String, "title": String}
}
</script>

<style scoped>



</style>