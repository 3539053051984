import { createI18n } from 'vue-i18n'

// Language definition import //
import enGB from '@/locale/en-GB'
import en from '@/locale/en'

// Initialize language //
const i18n = createI18n(
    {
      legacy: false,
      locale: "enGB",
      globalInjection: true,
      messages: {
        enGB: enGB,
        en: en
      }
    }
);

export default i18n;