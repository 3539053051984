<template>
    <v-sheet 
        class="i-field"
        :class="[fixedLabel ? 'i-field--std-label' : '']"
        >
        
        <label
            v-if="fixedLabel"
            :for="id"
            class="i-field__fixed-label"
            >{{ $t(label) }}:
        </label>

        <v-select 
            :modelValue="modelValue"
            :id="id"
            :name="id"
            :aria-describedby="id"
            :placeholder="placeholder"
            :persistent-placeholder="persistentPlaceholder"
            :items="items" 
            :item-title="itemtitle" 
            :item-value="itemvalue"     
            :return-object="returnObject"
            :variant="variant == ''? variant : 'outlined' " 
            :density="density == ''? density : 'compact' "             
            @update:modelValue="val => $emit('update:modelValue', val)"
            :label="$t(label)" 
            :aria-label="$t(label)"
            :disabled="disabled"
            />
    </v-sheet>     
</template>

<script lang="ts">
export default {
    props: ["id", "modelValue", "label", "items", "itemtitle", "itemvalue", "variant", "density", "placeholder", "persistentPlaceholder", "screenReader", "fixedLabel", "returnObject", "disabled"],
    methods: {}
}
</script>

<style scoped></style>