
import AboutAdvisgo from '@/assets/help/overview/about-advisgo.htm?raw'
import ActivateAccount from '@/assets/help/procedure/activate account.htm?raw'
import LogIn from '@/assets/help/procedure/log in.htm?raw'
import LogOut from '@/assets/help/procedure/log-out.htm?raw'
import ResetPassword from '@/assets/help/procedure/reset your password.htm?raw'
import LockedAccount from '@/assets/help/procedure/locked account.htm?raw'
import ArchitectOverview from '@/assets/help/overview/overview of architect.htm?raw'
import CreateUser from '@/assets/help/procedure/architect/create a user.htm?raw'
import ResendInvite from '@/assets/help/procedure/architect/resend an invite.htm?raw'
import EditUser from '@/assets/help/procedure/architect/edit a user.htm?raw'
import DeleteUser from '@/assets/help/procedure/architect/delete a user.htm?raw'
import ManageCampaigns from '@/assets/help/procedure/architect/manage campaigns.htm?raw'
import CreateCampaign from '@/assets/help/procedure/architect/create-campaign.htm?raw'
import CreateFlow from '@/assets/help/procedure/architect/create a flow.htm?raw'
import EditFlow from '@/assets/help/procedure/architect/edit a flow.htm?raw'
import CreateForm from '@/assets/help/procedure/architect/create a form.htm?raw'
import EditForm from '@/assets/help/procedure/architect/edit a form.htm?raw'
import ControlProperties from '@/assets/help/procedure/architect/control properties.htm?raw'
import FilterTable from '@/assets/help/procedure/architect/filter a table.htm?raw'
import RuntimeOverview from '@/assets/help/overview/overview of flow runner.htm?raw'
import RunFlow from '@/assets/help/procedure/runtime/run flow.htm?raw'
import AbandonFlow from '@/assets/help/procedure/runtime/abandon flow.htm?raw'
import Glossary from '@/assets/help/overview/glossary.htm?raw'
import LicenseManager from '@/assets/help/overview/overview of licence manager.htm?raw'
import CreateTenant from '@/assets/help/procedure/support/create a tenant.htm?raw'
import EditTenant from '@/assets/help/procedure/support/edit a tenant.htm?raw'
import RenewLicense from '@/assets/help/procedure/support/renew licence.htm?raw'

export default [
    {
        id: "advisgo-overview",
        title: "About Advisgo",
        html: AboutAdvisgo
    }, 
    {
        id: "activate account",
        title: "Activate your account",
        html: ActivateAccount
    }, 
    // {
    //     id: "log in",
    //     title: "Log in",
    //     html: LogIn
    // }, 
    // {
    //     id: "log-out",
    //     title: "Log out",
    //     html: LogOut
    // }, 
    {
        id: "reset your password",
        title: "Reset your password",
        html: ResetPassword
    }, 
    {
        id: "locked account",
        title: "Locked account",
        html: LockedAccount
    }, 
    {
        id: "overview of architect",
        title: "Architect",
        html: ArchitectOverview,
        children: [
            {
                id: "create a user",
                title: "Create a user",
                html: CreateUser
            }, 
            {
                id: "resend an invite",
                title: "Resend an invite",
                html: ResendInvite
            }, 
            {
                id: "edit a user",
                title: "Edit a user",
                html: EditUser
            }, 
            {
                id: "delete a user",
                title: "Delete a user",
                html: DeleteUser
            }, 
            {
                id: "manage campaigns",
                title: "Manage campaigns",
                html: ManageCampaigns
            }, 
            {
                id: "create-campaign",
                title: "Create a campaign",
                html: CreateCampaign
            }, 
            {
                id: "create a flow",
                title: "Create a flow",
                html: CreateFlow
            },
            {
                id: "edit a flow",
                title: "Edit a flow",
                html: EditFlow
            }, 
            {
                id: "create a form",
                title: "Create a form",
                html: CreateForm
            },
            {
                id: "edit a form",
                title: "Edit a form",
                html: EditForm
            }, 
            {
                id: "control properties",
                title: "Control properties",
                html: ControlProperties
            }, 
            {
                id: "filter a table",
                title: "Filter a table",
                html: FilterTable
            }, 
        ]
    }, 
    
    {
        id: "overview of flow runner",
        title: "Flow Runner",
        html: RunFlow,
        children: [
            // {
            //     id: "run flow",
            //     title: "Run flow",
            //     html: 
            // }, 
            // {
            //     id: "abandon flow",
            //     title: "Abandon flow",
            //     html: AbandonFlow
            // }, 
        ]
    }, 
    {
        id: "glossary",
        title: "Glossary",
        html: Glossary
    }, 
    // {
    //     id: "overview of license manager",
    //     title: "License manager",
    //     html: LicenseManager,
    //     children: [
    //         {
    //             id: "create a tenant",
    //             title: "Create tenant",
    //             html: CreateTenant
    //         }, 
    //         {
    //             id: "edit a tenant",
    //             title: "Edit a tenant",
    //             html: EditTenant
    //         }, 
    //         {
    //             id: "renew license",
    //             title: "Renew license",
    //             html: RenewLicense
    //         }, 
    //     ]
    // }, 
]