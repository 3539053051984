const settings = {
  _gatewayUrl: "VITE_APP_GATEWAY_PLACEHOLDER",

  getGatewayUrl: (): string => {
    if (settings._gatewayUrl.startsWith("VITE_APP")) {
      // Fetch from environment //
      settings._gatewayUrl = import.meta.env.VITE_APP_GATEWAY;
    }
    return settings._gatewayUrl;
  }
}

export default settings;
