<template>
            
    <svg version="1.1" class="flow-pattern-bb-bottom-left"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 243.9 327.4" style="enable-background:new 0 0 243.9 327.4;" xml:space="preserve" role="img" aria-labelledby="flow-pattern-bb-bottom-left-title" aria-describedby="flow-pattern-bb-bottom-left-desc">
     <title id="flow-pattern-bb-bottom-left-title">Flow diagram</title>
        <desc id="flow-pattern-bb-bottom-left-desc">Flow diagram</desc>
		<g>


			<rect class="st0" x="32.1" y="292.1" width="73.7" height="2.6" />
			<rect class="st0" x="0.1" y="227.5" width="137.3" height="2.6"/>
			<rect class="st0" x="0" y="162.9" width="103.2" height="2.6"/>
			<rect class="st0" x="32.1" y="162.9" width="2.6" height="164.4"/>
			<rect class="st0" x="65.7" y="67.8" width="2.6" height="259.5"/>
			<rect class="st0" x="0" y="100" width="133.9" height="2.6"/>
			<circle class="st0" cx="67" cy="228.8" r="3.9"/>
			<path class="st0" d="M45.9,331.3"/>
			<rect class="st0" x="103.2" y="194.7" width="68.8" height="2.6"/>
			<rect class="st0" x="103.2" y="162.9" width="2.6" height="131"/>
			<rect class="st0" x="134.8" y="227.7" width="2.6" height="99.6"/>
			<rect class="st0" x="134.9" y="162.7" width="37.1" height="2.6"/>
			<rect class="st0" x="103.6" y="67.3" width="2.6" height="65.8"/>
			<rect class="st0" x="65.7" y="67.3" width="38.8" height="2.6"/>
			<rect class="st0" x="103.6" y="130.9" width="132.1" height="2.6"/>
			<rect class="st0" x="134.4" y="39" width="2.6" height="156.2"/>
			<rect class="st0" x="32" y="260.3" width="140" height="2.6"/>
			<circle class="st1" cx="135.7" cy="101.2" r="3.9"/>
			<rect x="134.9" y="292.1" class="st4" width="69.9" height="2.6"/>
			<circle class="st0" cx="135.6" cy="40.5" r="3.9"/>
			<path class="st0" d="M-2.3,245.8"/>
			<rect class="st0" x="169.5" y="101.7" width="2.6" height="158.9"/>
			<rect x="200" y="130.9" class="st2" width="2.6" height="33.2"/>
			<circle class="st0" cx="170.8" cy="101.3" r="3.9"/>
			<circle class="st0" cx="234.9" cy="132.1" r="3.9"/>
			<circle class="st4" cx="136.1" cy="261" r="3.9"/>
			<circle class="st4" cx="204.8" cy="293.4" r="3.9"/>
			<circle class="st0" cx="104.5" cy="293.4" r="3.9"/>
			<circle class="st1" cx="67" cy="101.2" r="3.9"/>
			<rect x="134.9" y="260.3" class="st4" width="37.2" height="2.6"/>
			<polyline class="st4" points="137.4,293 134.8,293 134.8,260.3 137.4,260.3 	"/>
			<polyline class="st4" points="172.1,261 169.5,261 169.5,228.3 172.1,228.3 	"/>
			<rect x="169.5" y="227.5" class="st4" width="34.8" height="2.6"/>
			<circle class="st4" cx="204.3" cy="228.8" r="3.9"/>
			<circle class="st4" cx="136.1" cy="293.4" r="3.9"/>
			<polyline class="st3" points="105.8,293.4 103.2,293.4 103.2,227.7 105.8,227.7 	"/>
			<polyline class="st0" points="134.4,165.3 134.4,162.8 202.6,162.8 202.6,165.3 	"/>
			<polyline class="st3" points="66.7,230.1 66.7,227.5 105.8,227.5 105.8,230.1 	"/>
			<circle class="st2" cx="201.3" cy="164" r="3.9"/>
			<polyline class="st3" points="65.7,294.6 65.7,292.1 105.8,292.1 105.8,294.6 	"/>
			<polyline class="st3" points="65.7,292.2 68.3,292.2 68.3,327.4 65.7,327.4 	"/>
			<circle class="st3" cx="67" cy="228.8" r="3.9"/>
			<circle class="st3" cx="104.5" cy="228.8" r="3.9"/>
			<circle class="st3" cx="104.5" cy="293.4" r="3.9"/>
			<circle class="st3" cx="67" cy="293.4" r="3.9"/>
			<polyline class="st1" points="65.1,102.6 65.1,100 137,100 137,102.6 	"/>
			<polyline class="st1" points="134.4,101.2 137,101.2 137,164 134.4,164 	"/>
			<polyline class="st2" points="200,133.4 200,130.9 235.8,130.9 235.8,133.4 	"/>
			<circle class="st2" cx="234.9" cy="132.1" r="3.9"/>
			<circle class="st2" cx="201.3" cy="132.1" r="3.9"/>
			<circle class="st1" cx="135.7" cy="164" r="3.9"/>
			<circle class="st0" cx="33.4" cy="100.2" r="3.9"/>
			<circle class="st0" cx="33.4" cy="163.7" r="3.9"/>
			<circle class="st0" cx="67" cy="261.5" r="3.9"/>




		</g>
    </svg>
    
</template>


<script lang="ts">
    export default {
        name: "flow-pattern-BB-bottom-left"
    };
</script>