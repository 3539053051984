//@ts-nocheck

import type { App } from 'vue'

export default {
    install(app: App) {        
        window.equals = function(var1: any, var2: any){
            if(typeof(var1) !== typeof(var2)) return false
            if (var1.getMonth){
                return var1.getTime() === var2.getTime();
            } else 
                return var1 === var2;
        }

        window.notEquals = function(var1: unknown, var2:unknown){
            return !equals(var1, var2);
        }   

        window.moreThan = function(var1: unknown, var2: unknown){
            if(typeof(var1) !== typeof(var2)) return false
            if (var1.getMonth){

                return var1.getTime() > var2.getTime();
            } else 
                return var1 > var2;
        }

        window.lessThan = function(var1: unknown, var2: unknown){
            if(typeof(var1) !== typeof(var2)) return false
            if (var1.getMonth){
                return var1.getTime() < var2.getTime();
            } else 
                return var1 < var2;
        }

        window.moreThanOrEquals = function(var1: unknown, var2:unknown){
            return !lessThan(var1, var2);
        }  

        window.lessThanOrEquals = function(var1: unknown, var2:unknown){
            return !moreThan(var1, var2);
        }
        
        
        window.and = function(var1: boolean, var2:boolean){
            return var1 && var2;
        }

        window.or = function(var1: boolean, var2:boolean){
            return var1 || var2;
        }
           
    }
  }