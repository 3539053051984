<template>
            
    <svg version="1.1" class="flow-pattern-header-right-wide"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 376.8 177.2" style="enable-background:new 0 0 376.8 177.2;" xml:space="preserve" role="img" aria-labelledby="flow-pattern-header-right-title" aria-describedby="flow-pattern-header-right-desc">
     <title id="flow-pattern-header-right-title">Flow diagram</title>
        <desc id="flow-pattern-header-right-desc">Flow diagram</desc>
		<g>
			<rect x="275.2" class="st0" width="2.6" height="139.2"/>
            <rect x="209.5" class="st0" width="2.6" height="140"/>
            <rect x="146" class="st0" width="2.6" height="67.2"/>
            <rect x="276.5" y="-66.2" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 310.6075 -245.0358)" class="st0" width="2.6" height="198"/>
            <rect x="228.6" y="-80.5" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 296.3117 -163.5166)" class="st0" width="2.6" height="293.8"/>
            <rect x="113.4" y="65.1" class="st0" width="2.6" height="38.8"/>
            <rect x="275.3" y="3.7" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 380.47 -172.6139)" class="st0" width="2.6" height="200.5"/>
            <rect x="129.9" y="15.4" transform="matrix(6.123234e-17 -1 1 6.123234e-17 98.4015 164.0107)" class="st1" width="2.6" height="34.7"/>
            <rect x="113.7" class="st1" width="2.6" height="34.1"/>
            <circle class="st0" cx="177.6" cy="139.5" r="3.9"/>
            <circle class="st2" cx="84" cy="66.4" r="3.9"/>
            <circle class="st0" cx="211" cy="66.4" r="3.9"/>
            <circle class="st0" cx="179" cy="32.8" r="3.9"/>
            <circle class="st1" cx="147.3" cy="32.8" r="3.9"/>
            <rect x="275.2" y="31.6" class="st3" width="2.6" height="36.1"/>
            <rect x="241.8" class="st3" width="2.6" height="34.1"/>
            <rect x="257" y="16.8" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 291.1454 -225.4325)" class="st3" width="2.6" height="32.2"/>
            <circle class="st3" cx="276.5" cy="66.8" r="3.9"/>
            <circle class="st3" cx="243.1" cy="33.2" r="3.9"/>
            <circle class="st3" cx="276.5" cy="32.8" r="3.9"/>
            <rect x="82.7" y="31.5" class="st2" width="2.6" height="35.3"/>
            <rect x="66.7" y="15.4" transform="matrix(6.123234e-17 -1 1 6.123234e-17 35.1762 100.7854)" class="st2" width="2.6" height="34.7"/>
            <circle class="st2" cx="84.3" cy="32.6" r="3.9"/>
            <circle class="st2" cx="52.3" cy="32.8" r="3.9"/>
            <rect x="51" class="st2" width="2.6" height="34.1"/>
            <circle class="st0" cx="114.7" cy="103.2" r="3.9"/>
            <rect x="243.4" y="7.8" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 384.6369 -104.7714)" class="st0" width="2.6" height="264.2"/>
            <circle class="st4" cx="276.5" cy="139.2" r="3.9"/>
            <rect x="310.7" y="33.5" class="st0" width="2.6" height="105"/>
            <rect x="308.8" y="105" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 450.0125 -170.147)" class="st4" width="2.6" height="69.8"/>
            <rect x="343.7" y="65.3" class="st4" width="2.6" height="75.9"/>
            <circle class="st4" cx="276.5" cy="104" r="3.9"/>
            <rect x="275.2" y="102.6" class="st4" width="2.6" height="37.6"/>
            <rect x="359" y="49.8" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 426.6536 -293.8586)" class="st4" width="2.6" height="33.1"/>
            <rect x="176.3" y="103.5" class="st0" width="2.6" height="40"/>
            <circle class="st4" cx="345" cy="139.9" r="3.9"/>
            <circle class="st4" cx="345" cy="66.4" r="3.9"/>
            <circle class="st0" cx="114.7" cy="139.5" r="3.9"/>
		</g>
    </svg>
    
</template>

<script lang="ts">
    export default {
        name: "flow-pattern-header-right"
    };
</script>