<template>
    <li class="primary-nav__item">
        <a 
            @click="goToRoute"
            @keyup.enter="goToRoute"
            class="primary-nav__link"
            :class="{'nav-active': (active) }"
            tabindex="0"
        >
        <v-icon
            :icon="route.meta.icon"
            class="primary-nav__icon"/>
            <span :class="{'primary-nav__text': true, 'sr-only': screenReaderOnly}">{{ $t(route.meta.displayName) }}</span>
            <v-tooltip
                v-if="screenReaderOnly"
                activator="parent"
                location="start"
                class="i-tooltip i-tooltip--left"
                :title="$t(route.meta.displayName)"
            >
                {{ $t(route.meta.displayName) }}
            </v-tooltip>
        </a>
    </li>
</template>

<script lang="ts">

export default {
    props: ["route", "screenReaderOnly", "navOpen"],
    components: {},
    methods: {
        goToRoute() {
            if(this.route.name == undefined || this.route.name == '') {
                this.$router.push({name: this.route.children[0].name})
            } else {
                this.$router.push({name: this.route.name})
            }
            this.$emit("navOpen", !this.navOpen)
        }
    },
    computed: {
        active() {
            return this.$router.currentRoute.value.name == this.route.name
        }
    },
    data() { return {} },
}
</script>

<style scoped>

</style>