<template>
            
    <svg version="1.1" class="flow-pattern-bb-bottom-right"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 293 177" style="enable-background:new 0 0 293 177;" xml:space="preserve" role="img" aria-labelledby="flow-pattern-bb-bottom-right-title" aria-describedby="flow-pattern-bb-bottom-right-desc">
     <title id="flow-pattern-bb-bottom-right-title">Flow diagram</title>
        <desc id="flow-pattern-bb-bottom-right-desc">Flow diagram</desc>
		<g>
			<rect x="257.8" y="108.8" class="st0" width="2.6" height="68.2"/>
	<rect x="193.2" y="72.4" class="st0" width="2.6" height="104.6"/>
	<rect x="128.6" y="108.8" class="st0" width="2.6" height="69"/>
	
		<rect x="193.9" y="45.8" transform="matrix(3.631638e-10 -1 1 3.631638e-10 51.4719 338.8328)" class="st0" width="2.6" height="195.7"/>
	
		<rect x="148" y="-33.6" transform="matrix(3.198118e-10 -1 1 3.198118e-10 39.2588 259.3964)" class="st0" width="2.6" height="287.3"/>
	<rect x="65.6" y="43.2" class="st0" width="2.6" height="133.8"/>
	<circle class="st0" cx="194.4" cy="110.1" r="3.9"/>
	<circle class="st1" cx="66.9" cy="143.6" r="3.9"/>
	<circle class="st0" cx="6.2" cy="110.1" r="3.9"/>
	<circle class="st0" cx="194.4" cy="72.7" r="3.9"/>
	<path class="st2" d="M246.4,181"/>
	<rect x="225.9" y="72.4" class="st0" width="2.6" height="104.6"/>
	<rect x="160.3" y="41.6" class="st0" width="2.6" height="32.2"/>
	<rect x="32.7" y="41.4" class="st0" width="2.6" height="69.6"/>
	
		<rect x="192.8" y="7.1" transform="matrix(3.965664e-10 -1 1 3.965664e-10 121.5479 266.6244)" class="st0" width="2.6" height="131"/>
	
		<rect x="48.5" y="126.5" transform="matrix(1.471135e-10 -1 1 1.471135e-10 -93.7648 193.4174)" class="st1" width="2.6" height="34.1"/>
	
		<rect x="258.5" y="7.7" transform="matrix(2.011263e-10 -1 1 2.011263e-10 218.7915 300.6804)" class="st0" width="2.6" height="66.5"/>
	<rect x="257.8" y="109.5" class="st3" width="2.6" height="34.7"/>
	<circle class="st0" cx="34" cy="41.4" r="3.9"/>
	<rect x="128.6" y="40.5" class="st0" width="2.6" height="33.3"/>
	<circle class="st0" cx="259" cy="72.5" r="3.9"/>
	
		<rect x="129.7" y="9.5" transform="matrix(4.484476e-10 -1 1 4.484476e-10 89.641 172.3872)" class="st4" width="2.6" height="63.8"/>
	
		<rect x="81.5" y="56.3" transform="matrix(9.594376e-11 -1 1 9.594376e-11 10.5996 155.0425)" class="st0" width="2.6" height="31.9"/>
	<rect x="97.1" y="42.5" class="st0" width="3.2" height="31"/>
	<circle class="st4" cx="98.7" cy="41.4" r="3.9"/>
	<rect x="97.3" y="110.1" class="st0" width="2.6" height="33.6"/>
	<circle class="st0" cx="98.6" cy="110.1" r="3.9"/>
	<circle class="st0" cx="129.9" cy="143.6" r="3.9"/>
	<rect x="32.7" y="142.3" class="st1" width="2.6" height="34.6"/>
	<circle class="st0" cx="66.9" cy="41.4" r="3.9"/>
	<rect x="160.3" y="144.9" class="st0" width="2.6" height="32.2"/>
	<rect x="225.2" y="5.8" class="st2" width="2.6" height="34.7"/>
	<circle class="st2" cx="259.5" cy="40.9" r="3.9"/>
	<path class="st2" d="M222.6,41.4c0,2.1,1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9S222.6,39.2,222.6,41.4"/>
	
		<rect x="242.4" y="23.3" transform="matrix(4.505792e-10 -1 1 4.505792e-10 202.7242 284.6131)" class="st2" width="2.6" height="35.3"/>
	<path class="st2" d="M226.5,10.4c2.1,0,3.9-1.7,3.9-3.9s-1.7-3.9-3.9-3.9s-3.9,1.7-3.9,3.9S224.4,10.4,226.5,10.4"/>
	<rect x="160.3" y="40.5" class="st4" width="2.6" height="70.9"/>
	<circle class="st4" cx="161.6" cy="41.4" r="3.9"/>
	<circle class="st4" cx="161.6" cy="110.1" r="3.9"/>
	
		<rect x="241.8" y="92.8" transform="matrix(4.406298e-10 -1 1 4.406298e-10 132.9914 353.1289)" class="st3" width="2.6" height="34.5"/>
	
		<rect x="241.9" y="126.5" transform="matrix(4.377868e-10 -1 1 4.377868e-10 99.5309 386.8149)" class="st3" width="2.6" height="34.3"/>
	<rect x="225.9" y="142.3" class="st3" width="2.6" height="34.7"/>
	<circle class="st3" cx="227.2" cy="143.7" r="3.9"/>
	<circle class="st3" cx="259" cy="143.7" r="3.9"/>
	<circle class="st3" cx="227.2" cy="110.1" r="3.9"/>
	<circle class="st3" cx="259" cy="110.1" r="3.9"/>





		</g>
    </svg>
    
</template>


<script lang="ts">
    export default {
        name: "flow-pattern-BB-bottom-right"
    };
</script>