<!-- This icon is from <https://github.com/Templarian/MaterialDesign>, distributed under Apache 2.0 (https://www.apache.org/licenses/LICENSE-2.0) license-->
<template>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
    <g>
      <path d="M50,99.3c-6.6,0-13.1-1.3-19.2-3.9c-5.9-2.5-11.1-6-15.7-10.6C10.7,80.3,7.1,75,4.6,69.2
      C2.1,63.1,0.7,56.6,0.7,50c0-6.6,1.3-13.1,3.9-19.2c2.5-5.9,6-11.1,10.6-15.7S25,7.1,30.8,4.6C36.9,2.1,43.4,0.7,50,0.7
      c6.6,0,13.1,1.3,19.2,3.9c5.9,2.5,11.1,6,15.7,10.6c4.5,4.5,8.1,9.8,10.6,15.7c2.6,6.1,3.9,12.5,3.9,19.2c0,6.6-1.3,13.1-3.9,19.2
      c-2.5,5.9-6,11.1-10.6,15.7c-4.5,4.5-9.8,8.1-15.7,10.6C63.1,97.9,56.6,99.3,50,99.3z M50,7.2C26.4,7.2,7.2,26.4,7.2,50
      S26.4,92.8,50,92.8S92.8,73.6,92.8,50S73.6,7.2,50,7.2z"></path>
      <circle cx="31.8" cy="35.5" r="6.5"></circle>
      <circle cx="68.2" cy="35.5" r="6.5"></circle>
      <path d="M65.3,73.5H34.7c-1.6,0-2.9-1.3-2.9-2.9v-0.8c0-1.6,1.3-2.9,2.9-2.9h30.6c1.6,0,2.9,1.3,2.9,2.9v0.8
      C68.2,72.2,66.9,73.5,65.3,73.5z"></path>
  </g>
</svg>
</template>
