<template>
            
    <svg version="1.1" class="product-logo-icon"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 22.5 55" style="enable-background:new 0 0 22.5 55;" xml:space="preserve" role="img" aria-labelledby="icon_logo_title" aria-description="icon_logo_desc">
     <title id="icon_logo_title">Advisgo</title>
        <desc id="icon_logo_desc">Icon for Advisgo</desc>
        <g>
	        <path class="product-logo-icon__icon" d="M11.2,32.9c-2.2,0-4.2-0.5-5.9-1.4c-1.7-1-3-2.3-3.9-4.1C0.5,25.7,0,23.6,0,21.3c0-2.4,0.5-4.4,1.4-6.2
		c0.9-1.7,2.2-3.1,3.9-4.1c1.7-1,3.7-1.5,5.9-1.5c2.3,0,4.2,0.5,5.9,1.5c1.7,1,3,2.3,3.9,4.1c0.9,1.7,1.4,3.8,1.4,6.2
		c0,2.3-0.5,4.4-1.4,6.1c-0.9,1.7-2.3,3.1-3.9,4.1C15.5,32.5,13.5,32.9,11.2,32.9z M11.2,28.5c2,0,3.6-0.7,4.9-2
		c1.2-1.3,1.8-3.1,1.8-5.2c0-2.2-0.6-3.9-1.8-5.3c-1.2-1.3-2.8-2-4.9-2c-2,0-3.6,0.7-4.8,2c-1.2,1.3-1.8,3.1-1.8,5.3
		c0,2.2,0.6,3.9,1.8,5.3C7.7,27.9,9.3,28.5,11.2,28.5z"></path>
        </g>
        <path class="product-logo-icon__icon" d="M21.1,37.3c-0.2-0.3-0.3-0.5-0.5-0.8h-9.5v4h6.3c0.3,0.9,0.4,1.8,0.4,2.8c0,2.1-0.6,3.8-1.8,5.1
            c-1.2,1.3-2.8,2-4.9,2c-2,0-3.6-0.7-4.8-2c-1.2-1.3-1.8-3-1.8-5.2c0-1,0.1-1.9,0.4-2.7l-3.5-3.5c0,0.1-0.1,0.1-0.1,0.2
            C0.5,39,0,41,0,43.3c0,2.3,0.5,4.3,1.4,6c0.9,1.7,2.2,3,3.9,4c1.7,0.9,3.7,1.4,5.9,1.4c2.3,0,4.2-0.5,5.9-1.4c1.7-0.9,3-2.3,3.9-4
            c0.9-1.7,1.4-3.7,1.4-6C22.5,41,22.1,39,21.1,37.3z"></path>
        <path class="product-logo-icon__icon" d="M19.5,5.9c0.9,0,1.6-0.3,2.1-0.8c0.5-0.6,0.8-1.3,0.8-2.1c0-0.9-0.3-1.6-0.8-2.1C21.1,0.3,20.4,0,19.5,0
	c-0.9,0-1.6,0.3-2.1,0.8c-0.5,0.6-0.8,1.3-0.8,2.1c0,0.8,0.3,1.5,0.8,2.1C17.9,5.6,18.6,5.9,19.5,5.9z"></path>

    </svg>
    
</template>
    
<script lang="ts">
    export default {
        name: "product-icon-logo"
    };
</script>

<style lang="scss" scoped>
	.product-logo-icon {
        &__icon {
            fill: rgb(var(--v-theme-logo-icon));
        }
    }
</style>