<template>
    <v-form
        v-model="valid"
        ref="form"
        v-on:submit="submit"
        :id="id + '-form'"
        >

        <slot></slot>

        
        
        <!-- 
            <v-spacer v-if="hasFooter"></v-spacer>
            <slot name="footer"></slot> 
        -->
        
    </v-form>
</template>
<script lang="ts">
import InisoftTextInput from '@/components/forms/Inputs/InisoftTextInput.vue';
export default {
    props: ["id", "header", "hasFooter"],
    components: {
        InisoftTextInput
    },
    methods: {
        submit(evt: Event) {
            evt.preventDefault()
            this.$emit("submitted", evt)
        }
    },
    data() {
        return {
            valid: false
        }
    }
}
</script>

<style lang="scss">
    
</style>