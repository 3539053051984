<template>
  <v-app>
    <RouterView />
  </v-app>
</template>

<script lang="ts">
import { RouterView } from 'vue-router'
export default {
}

</script>

