import type { App } from "vue";
import { Vue3Mq, MqResponsive } from "vue3-mq"

// default breakpoints - customize this
// https://github.com/craigrileyuk/vue3-mq/tree/main/docs/configure
// https://github.com/craigrileyuk/vue3-mq/blob/main/docs/configure/presets.md
export default {
    install(app: App) {
        app.use(Vue3Mq, {
            preset: 'vuetify',
            breakpoints: {
              xs: 0,
              sm: 600,
              md: 960,
              //md: 768,
              lg: 1280,
              xl: 1920,
              xxl: 2560,
            },
            defaultBreakpoint: 'xs',
            defaultOrientation: 'landscape',
            defaultTheme: 'light',
            defaultMotion: 'no-preference'
          
        });
        app.component('mq-responsive', MqResponsive);
    }
  }
