<template>
            
    <svg version="1.1" class="flow-pattern-bb-top-left"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 270.8 214" style="enable-background:new 0 0 270.8 214;" xml:space="preserve" role="img" aria-labelledby="flow-pattern-bb-top-left-title" aria-describedby="flow-pattern-bb-top-left-desc">
     <title id="flow-pattern-bb-top-left-title">Flow diagram</title>
        <desc id="flow-pattern-bb-top-left-desc">Flow diagram</desc>
		<g>
			<rect x="32.4" y="0" transform="matrix(-1 -2.231576e-10 2.231576e-10 -1 67.351 173.1343)" class="st0" width="2.6" height="173.1"/>
	
		<rect x="97" y="0" transform="matrix(-1 -3.759567e-10 3.759567e-10 -1 196.5175 141.7943)" class="st0" width="2.6" height="141.8"/>
	
		<rect x="161.5" y="0" transform="matrix(-1 -2.853434e-10 2.853434e-10 -1 325.6553 106.4901)" class="st0" width="2.6" height="106.5"/>
	
		<rect x="80" y="-47.5" transform="matrix(-2.128521e-10 1 -1 -2.128521e-10 115.3402 -47.2039)" class="st0" width="2.6" height="163.1"/>
	
		<rect x="128.1" y="-61.7" transform="matrix(-1.982833e-10 1 -1 -1.982833e-10 197.0418 -61.6821)" class="st0" width="2.6" height="258.7"/>
	
		<rect x="224.5" y="0" transform="matrix(-1 -3.166140e-10 3.166140e-10 -1 451.6536 68.3149)" class="st0" width="2.6" height="68.3"/>
	
		<rect x="48.2" y="88.2" transform="matrix(-5.756658e-11 1 -1 -5.756658e-11 154.6605 55.7603)" class="st0" width="2.6" height="34.1"/>
        <circle class="st0" cx="98.3" cy="67.7" r="3.9"/>
        <circle class="st0" cx="225.8" cy="34.2" r="3.9"/>
        <circle class="st0" cx="258.7" cy="67.7" r="3.9"/>
        <circle class="st0" cx="33.7" cy="140.5" r="3.9"/>
        <circle class="st0" cx="98.3" cy="105" r="3.9"/>
        <path class="st2" d="M46.3-3.2"/>
        <path class="st2" d="M82.8-41.5"/>
	
		<rect x="64.2" y="33.3" transform="matrix(-1 -2.242239e-10 2.242239e-10 -1 131.0079 241.0273)" class="st0" width="2.6" height="174.4"/>
	
		<rect x="129.8" y="0" transform="matrix(-1 -2.821451e-10 2.821451e-10 -1 262.161 106.3582)" class="st0" width="2.6" height="106.4"/>
	
		<rect x="257.4" y="0" transform="matrix(-1 -3.166140e-10 3.166140e-10 -1 517.4478 68.3149)" class="st0" width="2.6" height="68.3"/>
	
		<rect x="129.4" y="71.7" transform="matrix(-1.130005e-10 1 -1 -1.130005e-10 235.8555 -25.4347)" class="st0" width="2.6" height="66.9"/>
	
		<rect x="241.6" y="17.1" transform="matrix(-5.756658e-11 1 -1 -5.756658e-11 277.0386 -208.7237)" class="st0" width="2.6" height="34.1"/>
	<circle class="st0" cx="65.5" cy="207.5" r="3.9"/>
	
		<rect x="31.9" y="107.1" transform="matrix(-1.126452e-10 1 -1 -1.126452e-10 173.6498 107.3794)" class="st0" width="2.6" height="66.7"/>
	
		<rect x="48.1" y="155.5" transform="matrix(-5.614461e-11 1 -1 -5.614461e-11 221.5168 122.8115)" class="st0" width="2.6" height="33.4"/>
	
		<rect x="31.4" y="174.5" transform="matrix(-1.126452e-10 1 -1 -1.126452e-10 240.533 175.1821)" class="st0" width="2.6" height="66.7"/>
	<circle class="st0" cx="33.7" cy="207.5" r="3.9"/>
	
		<rect x="32.4" y="0" transform="matrix(-1 -1.862018e-10 1.862018e-10 -1 67.3864 35.3479)" class="st3" width="2.6" height="35.3"/>
	
		<rect x="65.4" y="2.4" transform="matrix(-3.546356e-10 1 -1 -3.546356e-10 100.6606 -32.6654)" class="st3" width="2.6" height="63.2"/>
	
		<rect x="97" y="32.8" transform="matrix(-1 -1.790946e-10 1.790946e-10 -1 196.5175 101.6774)" class="st3" width="2.6" height="36.1"/>
	<circle class="st3" cx="33.7" cy="34.1" r="3.9"/>
	<circle class="st3" cx="98.3" cy="34.1" r="3.9"/>
	<circle class="st3" cx="98.3" cy="67.7" r="3.9"/>
	
		<rect x="161.5" y="33" transform="matrix(-1 -3.596107e-10 3.596107e-10 -1 325.6553 100.7102)" class="st2" width="2.6" height="34.7"/>
	<circle class="st2" cx="162.8" cy="67.7" r="3.9"/>
	<path class="st2" d="M201.5,67.7c0-2.1-1.7-3.9-3.9-3.9c-2.1,0-3.9,1.7-3.9,3.9c0,2.1,1.7,3.9,3.9,3.9
		C199.8,71.6,201.5,69.8,201.5,67.7"/>
	
		<rect x="178.7" y="50" transform="matrix(-3.603217e-10 1 -1 -3.603217e-10 247.6527 -112.293)" class="st2" width="2.6" height="35.3"/>
	<path class="st2" d="M162.8,29.9c-2.1,0-3.9,1.7-3.9,3.9s1.7,3.9,3.9,3.9s3.9-1.7,3.9-3.9S164.9,29.9,162.8,29.9"/>
	<circle class="st4" cx="65.5" cy="172.2" r="3.9"/>
	
		<rect x="48.9" y="154.5" transform="matrix(-3.596112e-10 1 -1 -3.596112e-10 222.3438 121.9845)" class="st4" width="2.6" height="35.3"/>
	
		<rect x="32.3" y="138.9" transform="matrix(-1 -3.603212e-10 3.603212e-10 -1 67.2557 312.4774)" class="st4" width="2.6" height="34.7"/>
	<circle class="st4" cx="33.7" cy="172.2" r="3.9"/>
	<circle class="st4" cx="33.6" cy="140.5" r="3.9"/>
	
		<rect x="15.7" y="123.5" transform="matrix(-3.598412e-10 1 -1 -3.598412e-10 157.5567 123.4724)" class="st4" width="2.6" height="34.1"/>
	<circle class="st4" cx="65.5" cy="140.5" r="3.9"/>
	
		<rect x="64.2" y="139.6" transform="matrix(-1 -3.603212e-10 3.603212e-10 -1 131.0079 313.8507)" class="st4" width="2.6" height="34.7"/>
	
		<rect x="97" y="108.9" transform="matrix(-3.546356e-10 1 -1 -3.546356e-10 238.7733 42.2558)" class="st4" width="2.6" height="63.2"/>
	<circle class="st4" cx="131.1" cy="139.9" r="3.9"/>




		</g>
    </svg>
    
</template>


<script lang="ts">
    export default {
        name: "flow-pattern-BB-top-left"
    };
</script>