import type { App } from 'vue'
import VueCookies from 'vue3-cookies'

// Initialize VueCookies plugin //
export default {
    install(app: App) {
        app.use(VueCookies, {
            expireTimes: "30d",
            path: "/",
            domain: "",
            secure: true,
            sameSite: "None"
        })
    }
  }