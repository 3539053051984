<template>
            
    <svg version="1.1" class="flow-pattern-header-right"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 271.1 177.2" style="enable-background:new 0 0 271.1 177.2;" xml:space="preserve" role="img" aria-labelledby="flow-pattern-header-right-title" aria-describedby="flow-pattern-header-right-desc">
     <title id="flow-pattern-header-right-title">Flow diagram</title>
        <desc id="flow-pattern-header-right-desc">Flow diagram</desc>
		<g>
			<rect x="229.6" class="st0" width="2.6" height="139.2"/>
			<rect x="163.9" class="st0" width="2.6" height="67.7"/>
			<rect x="100.4" class="st0" width="2.6" height="67.2"/>
			<rect x="200.9" y="-36.2" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 234.9508 -169.3791)" class="st0" width="2.6" height="137.9"/>
			<rect x="153" y="-50.4" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 220.655 -87.8599)" class="st0" width="2.6" height="233.7"/>
			<rect x="67.8" y="65.1" class="st0" width="2.6" height="38.8"/>
			<rect x="216.2" y="50.3" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 321.4322 -113.5761)" class="st0" width="2.6" height="107.2"/>
			<rect x="84.3" y="15.4" transform="matrix(6.123234e-17 -1 1 6.123234e-17 52.7896 118.3988)" class="st1" width="2.6" height="34.7"/>
			<rect x="68.1" class="st1" width="2.6" height="34.1"/>
			<circle class="st2" cx="38.4" cy="66.4" r="3.9"/>
			<circle class="st0" cx="165.4" cy="66.4" r="3.9"/>
			<circle class="st0" cx="133.4" cy="32.8" r="3.9"/>
			<circle class="st1" cx="101.7" cy="32.8" r="3.9"/>
			<rect x="229.6" y="31.6" class="st3" width="2.6" height="36.1"/>
			<rect x="196.2" class="st3" width="2.6" height="34.1"/>
			<rect x="211.4" y="16.8" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 245.5335 -179.8206)" class="st3" width="2.6" height="32.2"/>
			<circle class="st3" cx="230.8" cy="66.8" r="3.9"/>
			<circle class="st3" cx="197.5" cy="33.2" r="3.9"/>
			<circle class="st3" cx="230.8" cy="32.8" r="3.9"/>
			<rect x="37.1" y="31.5" class="st2" width="2.6" height="35.3"/>
			<rect x="21.1" y="15.4" transform="matrix(6.123234e-17 -1 1 6.123234e-17 -10.4358 55.1735)" class="st2" width="2.6" height="34.7"/>
			<circle class="st2" cx="38.7" cy="32.6" r="3.9"/>
			<circle class="st2" cx="6.6" cy="32.8" r="3.9"/>
			<rect x="5.3" class="st2" width="2.6" height="34.1"/>
			<circle class="st0" cx="69.1" cy="103.2" r="3.9"/>
			<circle class="st0" cx="230.8" cy="139.2" r="3.9"/>
			<circle class="st0" cx="165.2" cy="104" r="3.9"/>

		</g>
    </svg>
    
</template>


<script lang="ts">
    export default {
        name: "flow-pattern-header-right-wide"
    };
</script>