// Types
import type { App } from 'vue'

// Plugins
import router from '../router'
import pinia from './pinia'
import i18n from './i18n'
import vuetify from './vuetify'
import vuecookies from './vuecookies'
import vuemediaquery from './vuemediaquery'
import VueSweetalert2 from './sweetalert'
import utilities from './utilities'

export function registerPlugins (app: App) {
    app
      .use(pinia)
      .use(i18n)
      .use(vuetify)
      .use(router)
      .use(vuecookies)
      .use(vuemediaquery)
      .use(VueSweetalert2)
      .use(utilities)
  }
  